import {
	getDurationTextFromSeconds,
	sentryCaptureException
} from '$lib/components/component-functions';
import dayjs from 'dayjs';
import { ErrorCodes } from 'fixee-server';
import { ReportGenerationStatus } from 'fixee-server/lib/services/portal/portal.shared';
import parsePhoneNumber from 'libphonenumber-js';
import toast from 'svelte-french-toast';

export const OPEN_WIDGET_PICKER_EVENT = 'open-widget-picker';
export const CLOSE_WIDGET_PICKER_EVENT = 'close-widget-picker';
export const PAUSE_OTHER_AUDIO_PLAYERS_EVENT = `pause-other-audio-players`;
export const PAUSE_OTHER_AUDIO_RECORDERS_EVENT = `pause-other-audio-recorders`;
export const OPEN_CHATBOT_DOCUMENT_EVENT = 'open-chatbot-document-event';
export const HIDE_FULLSCREEN_JOIN_LIVE = `hide-fullscreen-join-live`;
export const UNIQUE_DEVICE_ID = 'unique-device-id'; // Used to identify a device, for push notifications subscriptions for example
export const SELECTED_LOCALE = 'selected-locale';
export const ATOM_REQUEST_FULLSCREEN = 'atom-request-fullscreen';
export const ATOM_EXIT_FULLSCREEN = 'atom-exit-fullscreen';
export const ATOM_TOGGLE_FULLSCREEN = 'atom-toggle-fullscreen';
export const START_RECORD_LIVE_VIDEO = 'start-record-live-video';
export const STOP_RECORD_LIVE_VIDEO = 'stop-record-live-video';
export const RESET_LOCAL_VIDEO = 'reset-local-video';
export const PORTAL_CHAT_SCROLL_TO_BOTTOM = 'portal-chat-scroll-to-bottom';
export const PORTAL_CHAT_SCROLL_TO_BOTTOM_FORCE = 'portal-chat-scroll-to-bottom-force'; // Scroll even if we are not at the bottom already
export const AUDIO_RECORDER_STOP = (recorderId: string) => 'audio-recorder-stop-' + recorderId;
export const PORTAL_CHAT_INPUT_UPLOAD_FILES = 'portal-chat-input-upload-files';
export const PORTAL_CHAT_INPUT_SET_TEXT = 'portal-chat-input-set-text';
export const PORTAL_CHAT_INPUT_FOCUS = 'portal-chat-input-focus';
export const PORTAL_CHAT_INPUT_SET_FILES = 'portal-chat-input-set-files';
export const JOIN_LIVE_WIDGET_BUTTON = 'join-live-widget-button';

export const BUTTON_RED_CLASSES = '!bg-red-500 hover:!bg-red-600 !text-white !fill-white';
export const BUTTON_RED_CLASSES_BORDER =
	'!border-red-500 hover:!border-red-600 !text-red-500 !fill-red-500';
export const BUTTON_GREEN_CLASSES = '!bg-green-500 hover:!bg-green-600 !text-white !fill-white';
export const BUTTON_GREEN_CLASSES_BORDER =
	'!border-green-500 hover:!border-green-600 !text-green-500 !fill-green-500';
export const BUTTON_PURPLE_CLASSES_BORDER =
	'!border-purple-500 hover:!border-purple-600 !text-purple-500 !fill-purple-500';
export const BUTTON_ORANGE_CLASSES = '!bg-orange-500 hover:!bg-orange-600 !text-white !fill-white';
export const PORTAL_MAIN_WIDTH = 448;
export const MODAL_SIZE_TO_REMOVE_WITHOUT_DESCRIPTION = 230;
export const PERSONAL_TEAM_COLOR = 'blue';
export const OPEN_FEEDBACK_MODAL = `open-feedback-modal`;
export const LOCAL_STORAGE_SHOW_MY_ISSUER_PORTALS = 'show-my-issuer-portals';
export const PORTAL_ARCHIVED_STATUS = -1;
export const BREAKPOINTS = {
	mainButtonsHeader: 1024
};
export const TABLE_HEADER_CLASS_PREFIX = 'CLASS:';

// If you change a value here, update the translation values !
export const FEEDBACK_TYPES = [
	'sales_question',
	'subscription',
	'suggestion',
	'bug',
	'other'
] as const;
type FeedbackTypesTuple = typeof FEEDBACK_TYPES;
export type FeedbackTypes = FeedbackTypesTuple[number];

export const CHANNEL_TYPES = ['sms', 'whatsapp', 'email'] as const;
type ChannelTypesTuple = typeof CHANNEL_TYPES;
export type ChannelTypes = ChannelTypesTuple[number];

export type OpenFeedbackModalEvent = {
	requestCallback?: boolean;
	type: any;
	message?: string;
};

// eslint-disable-next-line prefer-const
export const globals = {
	lastLiveDisconnectedAt: dayjs(new Date(1970, 1, 1)),
	appHistory: [] as string[]
};

export type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;

export type PdfSearchResult = {
	pageNumber: number;
	text: string;
	aroundText: string;
	position: number;
	id: string;
};

// for portals
export const getIsDesktopUi = (innerWidth: number) => {
	return innerWidth > PORTAL_MAIN_WIDTH * 2;
};

export const isClientSide = () => {
	return typeof window !== 'undefined';
};

export const isValidEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

export const isValidFirstAndLastName = (name: string | null | undefined) => {
	if (!name) return false;
	return name.length >= 2 && name.length <= 40;
};

export const isValidTeamName = (name: string) => name.length >= 2 && name.length <= 50;

export const isValidAssistantName = (name: string) => name.length >= 2 && name.length <= 150;
export const isValidIntroductionMessage = (str?: string) =>
	str && str.length >= 2 && str.length <= 1000;
export const isValidProblemDescription = (str?: string) =>
	str && str.length >= 2 && str.length <= 512;

export const isValidPhone = (phone: string | null | undefined) => {
	if (!phone) return false;

	if (!parsePhoneNumber(phone)?.isValid()) return false;

	return /^\+[0-9]+$/.test(phone);
};

// We only accept letters, numbers and spaces
export const isValidSmsName = (str: string) => {
	const validCharacters = /^[A-Za-z0-9 ]+$/;
	return validCharacters.test(str) && str.length <= 11;
};

export const getPortalFiltersLocalStorageKey = (teamId: string) => {
	return `portalFilters-${teamId}`;
};

export const isError = (error: any, errorCode: ErrorCodes) => {
	return (error as any).data?.errorCode === errorCode;
};

export const currentBrowserSupportsPush = () => {
	return 'PushManager' in window && 'serviceWorker' in navigator && 'Notification' in window;
};

export const getFileTypeFromMimeType = (_: (key: string) => string, mimeType: string): string => {
	const key = `mimeTypes.${mimeType}`;
	const finalValue = _(key);

	if (key === finalValue) {
		return mimeType;
	}

	return finalValue;
};

export const handleFormError = (error: any, formErrors: any, _: any) => {
	sentryCaptureException(error);

	const errorCode = (error as any).data?.errorCode;

	if (error.message === 'Invalid login') formErrors.all = [_(`apiErrors.InvalidLogin`)];
	else if ([ErrorCodes.USER_ALREADY_EXISTS].includes(errorCode)) {
		// Some errors can be put in specific fields
		formErrors.email = [_(`apiErrors.${errorCode}`)];
	} else if ([1004, 1005].includes(errorCode)) {
		// Some errors can be put in specific fields
		formErrors.phone = [_(`apiErrors.${errorCode}`)];
	} else if ([1007, 1009, 1011, 1012].includes(errorCode)) {
		toast.error(_(`apiErrors.${errorCode}-toast`));
	} else if (errorCode === 1008) {
		const seconds = (error as any).data?.seconds;
		toast.error(
			_(`apiErrors.${errorCode}-toast`, {
				values: {
					duration: getDurationTextFromSeconds(seconds)
				}
			})
		);
	} else if (errorCode === 1014) {
		const portalRef = (error as any).data?.portalRef;
		toast.error(
			_(`apiErrors.${errorCode}-toast`, {
				values: {
					portalRef
				}
			})
		);
	} else if (errorCode === 1010) {
		const samePhone = (error as any).data?.samePhone;
		const sameEmail = (error as any).data?.sameEmail;

		if (samePhone) formErrors.phone = [_(`apiErrors.${errorCode}-samePhone`)];
		if (sameEmail) formErrors.email = [_(`apiErrors.${errorCode}-sameEmail`)];
	} else if (errorCode) {
		formErrors.all = [_(`apiErrors.${errorCode}`)];
	} else {
		// CAMERA D2J0 UTILIS2E sur TEAMS !!! Mettre une ERREUR !!!!! Dire que TEAMS ou un autre truc utilise D2JA la camera
		// CAMERA D2J0 UTILIS2E sur TEAMS !!! Mettre une ERREUR !!!!!
		// CAMERA D2J0 UTILIS2E sur TEAMS !!! Mettre une ERREUR !!!!!
		// CREATION DU COMPTE ? qui bug ? A causedu vpn surement

		// VOIR DOC Azure video, si il y a aussi des IP a autoriser !! ou quoi juste pour l'info
		// VOIR AWS si on peut avoir plus de crédits gratuits !!

		console.error('handleFormError Uncaught error', error);

		formErrors.all = [error.message];
	}
	return formErrors;
};

export function iOS() {
	return (
		['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
			navigator.platform
		) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
}

export function isChrome() {
	return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}

export function isSafari() {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isFirefox() {
	return navigator.userAgent.toLowerCase().includes('firefox');
}

export function isAndroid() {
	return /(android)/i.test(navigator.userAgent);
}

export const isIphone = () => {
	const u = navigator.userAgent;
	return !!u.match(/iPhone/i);
};
export const isIpad = () => {
	const u = navigator.userAgent;
	return !!u.match(/iPad/i);
};

export function getChromeVersion() {
	const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

	return raw ? parseInt(raw[2], 10) : false;
}

export function isTouchScreen() {
	return isClientSide() && 'ontouchstart' in document.documentElement;
}

export function isMobileOrTablet() {
	return isClientSide() && 'ontouchstart' in document.documentElement;
}

// https://hackernoon.com/using-javascript-to-create-and-generate-uuids
export function randomUUID() {
	return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
}

export function getDateDivider(date: Date, _: any) {
	const createdAt = dayjs(date);
	const now = dayjs();
	const diff = now.diff(createdAt, 'day');

	// We also check the date because diff is 0 is there is less than 24 hours between the two dates event if the day is different
	if (diff === 0 && now.date() === createdAt.date()) {
		return createdAt.format('HH:mm');
	} else if (diff < 7) {
		return createdAt.format('ddd HH:mm');
	} else if (diff < 365) {
		return createdAt.format(_('portal.messages.dateFormat'));
	} else {
		return createdAt.format(_('portal.messages.dateFormatWithYear'));
	}
}

export const PDF_HIGHLIGHT_BACKGROUND_COLOR = '#FF0000';
export const PDF_SELECTED_HIGHLIGHT_BACKGROUND_COLOR = 'yellow';
export const getPdfPageTextLayerId = (idx: number) => `pdf-text-layer-page-${idx}`;
export const getPdfTextHighlightId = (id: string) => `text-highlight-${id}`;
export const getPdfTextHighlightClass = (pdfId: string) => `text-highlight-class-${pdfId}`;
export const getPdfPageClass = (pdfId: string) => `page-${pdfId}`;
export const getPdfPagesDivId = (pdfId: string) => `pages-div-holder-${pdfId}`;
export const getPortalMessageId = (messageId: string) => `portal-message-${messageId}`;
export const getPortalMessageDocumentId = (documentId: string) =>
	`portal-message-document-${documentId}`;
export const getPortalConversationId = (conversationId: string) =>
	`portal-conversation-${conversationId}`;

export function removeExtraSpaces(inputString: string) {
	return inputString.replace(/\s+/g, ' ').trim();
}

export function scrollIntoViewWithinParent(
	childElem: HTMLElement | null,
	parentElem: HTMLElement | null,
	middleElem?: HTMLElement | null, // This is useful if there is a relative element in the middle of the parent and the child
	duration: number | undefined = 1 // Duration of the animation in milliseconds
) {
	if (!childElem || !parentElem) return;

	const startTime = performance.now();

	const startTop = parentElem.scrollTop;
	const startLeft = parentElem.scrollLeft;

	const targetTop =
		childElem.offsetTop +
		(middleElem?.offsetTop || 0) -
		parentElem.offsetTop -
		parentElem.clientHeight / 2 +
		childElem.clientHeight / 2;

	const targetLeft =
		childElem.offsetLeft -
		parentElem.offsetLeft -
		parentElem.clientWidth / 2 +
		childElem.clientWidth / 2;

	function animate(time: number) {
		const progress = Math.min((time - startTime) / duration, 1);

		if (parentElem) {
			parentElem.scrollTop = startTop + (targetTop - startTop) * progress;
			parentElem.scrollLeft = startLeft + (targetLeft - startLeft) * progress;
		}

		if (progress < 1) {
			window.requestAnimationFrame(animate);
		}
	}

	window.requestAnimationFrame(animate);
}

export function scrollIntoViewWithinParentForPdfPages(
	childElem: HTMLElement | null,
	parentElem: HTMLElement | null
) {
	const middleElem = childElem?.parentElement?.parentElement;
	if (!childElem || !parentElem || !middleElem) return;

	scrollIntoViewWithinParent(childElem, parentElem, middleElem);
}

// program to check the occurrence of a character

export function countCharInString(str: string, letter: string) {
	// creating regex
	const re = new RegExp(letter, 'g');

	// matching the pattern
	const count = str.match(re)?.length;

	return count || 0;
}

export const fixedNumber = (nb: number, limit: number) => nb.toFixed(limit).replace(/[.,]00$/, '');

export function capitalizeFirstLetter(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function truncateString(str: string, maxLength: number) {
	if (str.length > maxLength) {
		return str.slice(0, maxLength) + '...';
	}
	return str;
}

export const isGeneratingReport = (status: ReportGenerationStatus) => {
	return ['generating', 'transcription', 'llm-analyzing'].includes(status);
};

export const openFeedbackSubscriptionModal = (message: string) => {
	window.dispatchEvent(
		new CustomEvent<OpenFeedbackModalEvent>(OPEN_FEEDBACK_MODAL, {
			detail: {
				type: 'subscription',
				message: message,
				requestCallback: true
			}
		})
	);
};
